import React from 'react'
import { SimpleTabs } from '../../../components/Form'
import Layout from '../../../components/Layout'
import { buyerNavTo } from '../../../components/navigation'
import ViewListingTable from '../../../components/ViewListingsTable'

const Page = () => {
  return <Layout>
        <Layout.Title>
            <h5>Market Access</h5>
        </Layout.Title>
        <Layout.CardGrey>
            <SimpleTabs elements={[{ label: 'Active' }]} selectedIndex={0} size="md" />
            <div className="card-body rounded-soft bg-white">
                <div className="container-fluid mb-4">
                    <ViewListingTable actionButtonLabel="View" actionButtonFunction={listingId => buyerNavTo.listingsDetailsPage(listingId)} isVendor={false} />
                </div>
            </div>
        </Layout.CardGrey>
    </Layout>
}

export default Page
